import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [
    "selectedList",
    "popularList",
    "searchList",
    "filterInput",
  ]

  static values = {
    inputName: String
  }

  connect() {
    console.log("ActivitiesSelectorController connected")

    this.selectedListTargets.forEach((item) => {
      item.addEventListener("click", () => {
        this.removeItem(item)
      })
    })

    this.popularListTargets.forEach((item) => {
      item.addEventListener("click", () => {
        this.addItem(item)
      })
    })

    this.searchListTargets.forEach((item) => {
      item.addEventListener("click", () => {
        this.addItem(item)
      })
    })

    this.filterInputTarget.addEventListener("input", () => {
      this.filterList()
    })
  }

  clearAll() {
    this.targetListTargets.forEach((item) => {
      this.removeItem(item)
    })
    this.finalTargetListTargets.forEach((item) => {
      item.querySelectorAll("li").forEach((li) => {
        if (li.dataset.persisted) {
        } else {
          li.remove()
        }
      })
    })
    this.filterList()
  }

  addItem(item) {
    this.selectedListTarget.appendChild(
      this.selectedListItem(item)
    )
    this.filterList()
  }

  removeItem(item) {
    item.remove()
    this.filterList()
  }

  selectedListItem(item) {
    const activityId = item.dataset.activityId
    const activityName = item.textContent.trim()
    const listItem = document.createElement("li")
    listItem.dataset.activityId = activityId
    listItem.textContent = activityName
    listItem.classList.add("bg-purple-600", "text-gray-900", "rounded-full", "px-2.5", "hover:bg-purple-500", "cursor-pointer")
    // create hidden input
    const input = document.createElement("input")
    input.type = "hidden"
    input.name = `${this.inputNameValue}[${this.selectedListTargets.length}][activity_id]`
    input.value = activityId
    listItem.appendChild(input)

    listItem.addEventListener("click", () => {
      this.removeItem(listItem)
    })

    return listItem
  }

  filterList() {
    this.filterSearchList()
    this.filterPopularList()
  }

  filterSearchList() {
    const filterText = this.filterInputTarget.value.trim().toLowerCase()

    const selectedListIDs = Array.from(this.selectedListTargets).map((item) => {
      return item.dataset.activityId
    })

    this.searchListTargets.forEach((item) => {
      const activityName = item.textContent.trim().toLowerCase()
      if (activityName.includes(filterText) && !selectedListIDs.includes(item.dataset.activityId)) {
        item.style.display = "block"
      } else {
        item.style.display = "none"
      }
    })
  }

  filterPopularList() {
    const selectedListIDs = Array.from(this.selectedListTargets).map((item) => {
      return item.dataset.activityId
    })
    this.popularListTargets.forEach((item) => {
      if (selectedListIDs.includes(item.dataset.activityId)) {
        item.style.display = "none"
      } else {
        item.style.display = "block"
      }
    })
  }

  get selectedListTargets() {
    return this.selectedListTarget.querySelectorAll("li")
  }

  get popularListTargets() {
    return this.popularListTarget.querySelectorAll("li")
  }

  get searchListTargets() {
    return this.searchListTarget.querySelectorAll("li")
  }

  createEvent(event_name) {
    const event = new CustomEvent(event_name)
    window.dispatchEvent(event)
  }
}