$(document).on( "turbolinks:load", function() {
  // https://flatpickr.js.org/
  // https://flatpickr.js.org/formatting/
  flatpickr("#event_starts_at",
    {
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      altInput: true,
      altFormat: "F j, Y h:i K"
    }
  );
  $('#moderator_event_form').on('submit', function(){
    return $('#moderator_event_form input[name="event[starts_at]"]').val().length > 0
  });
})
