import { Controller } from "@hotwired/stimulus"
import CableReady from 'cable_ready'
import consumer from "channels/consumer"

export default class extends Controller {
  static targets = [
    "restrictionOptions",
    "blockModal",
    "submitBlockButton",
    "closeBlockButton",
    "messageModal",
    "messageModalTitle",
    "reportForm",
    "closeReportButton",
    "reportValidationError",
    "submitReportButton",
    "reportMessageForm",
    "closeReportMessageButton",
    "submitReportMessageButton",
    "reportMessageValidationError",
  ]

  static values = {
    restrictionRedirectPath: String,
    collaboratorId: String
  }

  connect() {
    this.subscription = consumer.subscriptions.create(
      {
        channel: "BlockedUserChannel",
        id: this.collaboratorIdValue,
      },
      {
        received(data) {
          if (data.cableReady) CableReady.perform(data.operations)
        }
      }
    );
  }

  initialize() {
  }

  openReportForm() {
    this.clearModal()
    this.reportFormTarget.classList.remove("d-none")
  }

  openBlockModal() {
    this.clearModal()
    this.blockModalTarget.classList.remove("d-none")
  }

  openReportMessageForm() {
    this.clearModal()
    this.reportMessageFormTarget.classList.remove("d-none")
  }

  clearModal() {
    this.restrictionOptionsTarget.classList.add("d-none")
    this.reportFormTarget.classList.add("d-none")
    this.messageModalTarget.classList.add("d-none")
    this.reportMessageFormTarget.classList.add("d-none")
    this.blockModalTarget.classList.add("d-none")
  }

  resetModal() {
    this.clearModal()
    this.restrictionOptionsTarget.classList.remove("d-none")
  }

  openMessageModal() {
    this.clearModal()
    this.messageModalTarget.classList.remove("d-none")
  }

  reportSubmit() {
    this.submitReportButtonTarget.disabled = true
    this.closeReportButtonTarget.classList.add('disabled')
  }

  reportError(event) {
    this.reportValidationErrorTarget.innerHTML = event.detail[0].errors[0]
  }

  reportComplete() {
    this.submitReportButtonTarget.disabled = false
    this.closeReportButtonTarget.classList.remove('disabled')
  }

  reportSuccess(event) {
    var data = event.detail[0];
    this.messageModalTitleTarget.innerHTML = data.collaboratorName + ' has been ' + data.restrictionType
  }

  reportMessageSubmit() {
    this.submitReportMessageButtonTarget.disabled = true
    this.closeReportMessageButtonTarget.classList.add('disabled')
  }

  reportMessageError(event) {
    this.reportMessageValidationErrorTarget.innerHTML = event.detail[0].errors[0]
  }

  reportMessageComplete() {
    this.submitReportMessageButtonTarget.disabled = false
    this.closeReportMessageButtonTarget.classList.remove('disabled')
  }

  reportMessageSuccess(event) {
    this.messageModalTitleTarget.innerHTML = 'The message has been reported'
  }

  blockSubmit() {
    this.submitBlockButtonTarget.classList.add('disabled')
    this.closeBlockButtonTarget.classList.add('disabled')
  }

  blockComplete() {
    this.submitBlockButtonTarget.classList.remove('disabled')
    this.closeBlockButtonTarget.classList.remove('disabled')
  }

  restrictionRedirect() {
    window.location = this.restrictionRedirectPathValue
  }
}
