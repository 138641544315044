import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static values = {
    startCollaboration: Boolean,
    refreshRequest: Boolean,
    targetId: String
  }

  connect() {
    if(this.startCollaborationValue){
      console.log('start collaborators refresh');
      if($(`#${this.targetIdValue}`).length && !this.refreshRequestValue ) {
        gon.timerID = setInterval(function() {
          $.ajax({
            url: document.URL,
            type: "GET",
            dataType: "script",
            data: {
              refreshRequest: true
            }
          });
        }, 10000); // refresh remote every 10 seconds
      }
    }
    else{
      clearInterval(gon.timerID); 
      console.log('stop collaborators refresh');
    }
  }
}
