import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static values = {
    targetType: String,
    targetId: String
  }

  connect() {
    switch(this.targetTypeValue) {
      case 'daily-api':
        setTimeout(() => { console.log("loading daily api js"); dailyAPI(); }, 500);
      case 'time_zone':
        const timezone = jstz.determine();
        const selected_tz = gon.time_zones[timezone.name()]
        $(`#${this.targetIdValue}`).val(selected_tz);
    }
  }

  handleVerificationInput(e){
    let value = e.currentTarget.value
    value = value.replace(/[^0-9\.]/g,'');
    if (value.length == 6) {
      $(`#${this.targetIdValue}`).blur();
    }
  }
}
