import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    refreshRequest: Boolean,
  }

  connect() {
    if($("#start_training_div").length)
      return clearInterval(gon.timerID)

    if (this.refreshRequestValue)
      return;

    gon.timerID = setInterval(function() {
      $.ajax({
        url: document.URL,
        type: "GET",
        dataType: "script",
        data: {
          refreshRequest: !this.refreshRequestValue
        }
      });
    }, 5000);
  }
}
