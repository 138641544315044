import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    url: String,
    delay: Number
  }

  connect () {
    super.connect();
    this.redirect();
  }

  disconnect() {
    clearTimeout(this.timeout)
  }

  redirect() {
    this.timeout = setTimeout(
      () => window.location.replace(this.urlValue),
      (this.delayValue || 5000)
    );
  }
}
