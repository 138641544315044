import ApplicationController from './application_controller'

// Import UJS so we can access the Rails.ajax method
// import Rails from "@rails/ujs";

export default class extends ApplicationController {
  static targets = [
    'form', 'input'
  ]

  static values = {
    answerRequired: Boolean,
    answerExists: Boolean,
    isValid: Boolean,
    beenSubmitted: Boolean
  }

  connect() {
    super.connect()
  }

  initialize() {
  }

  validate(event) {
    const form = this.hasFormTarget ? this.formTarget : event.target.closest('form');

    if (event.target.tagName == "FORM") {
      this.beenSubmittedValue = true;
    }

    if (!this.beenSubmittedValue) {
      return;
    }

    let isValid = this.validateForm(form);

    if (!this.isValidValue) {
      this.showInvalid();
      event.preventDefault();

      form.addEventListener('change', () => {
        document.querySelector('input[type=submit]').disabled = !this.validateForm(form)
      });
    } else {
      this.hideInvalid();
    }
  }

  resetSubmit() {
    document.querySelector('input[type=submit]').disabled = false
  }

  validateForm(form) {
    this.isValidValue = true;

    // Tell the browser to find any required fields
    let requiredFieldSelectors = 'textarea:required, input:required, select:required';
    let requiredFields = form.querySelectorAll(requiredFieldSelectors);
    let firstErrorFieldFocused = null;
    let s = this;

    requiredFields.forEach((field, index) => {
      // For each required field, check to see if the value is empty
      // if so, we focus the field and set our value to false
      switch(field.type) {
      case "radio":
        s.isValidValue = this.validateRadio(field);
        break;
      case "select-one":
        s.isValidValue = this.validateSelect(field);
        break;
      default:
        s.isValidValue = this.validateInput(field);
        break;
      }
      if ((s.isValidValue == false) && (firstErrorFieldFocused == null)) {
        firstErrorFieldFocused = true
        field.focus();
      }
    });

    // Search for any browser invalidated input fields and focus them
    let invalidFields = form.querySelectorAll('input:invalid');

    invalidFields.forEach((field, index) => {
      if (!field.disabled) {
        this.isValidValue = false;
      }
    });

    return this.isValidValue;
  }

  validateInput(field) {
    if (!field.disabled && !field.value.trim()) {
      this.showFieldError(field, '.form-control')
      return false;
    } else {
      this.hideFieldError(field, '.form-control')
      return true;
    }
  }

  validateSelect(field) {
    if (!field.disabled && !field.value.length) {
      this.showFieldError(field, '.plain-select')
      return false;
    } else {
      this.hideFieldError(field, '.plain-select')
      return true;
    }
  }

  validateRadio(field) {
    let radios = Array.from(document.getElementsByName(field.name)).find(e => e.checked && !e.disabled);

    if (radios == undefined) {
      this.showFieldError(field, '.form-control')
      return false;
    } else {
      this.hideFieldError(field, '.form-control')
      return true;
    }
  }

  showFieldError(field, errorTarget) {
    let errors = this.getNextSibling(
      field.type === 'radio' ? field.parentElement : field,
      'field_with_errors'
    )

    if (errors) {
      errors.classList.remove('d-none')
      field.closest(errorTarget).classList.add('is-invalid')
    }
  }

  hideFieldError(field, errorTarget) {
    let errors = this.getNextSibling(
      field.type === 'radio' ? field.parentElement : field,
      'field_with_errors'
    )

    if (errors) {
      errors.classList.add('d-none')
      field.closest(errorTarget).classList.remove('is-invalid')
    }
  }

  hideInvalid() {
    let error_container = document.getElementById('required_field_errors');
    if (error_container) {
      error_container.innerText = '';
    }
  }

  showInvalid() {
    let error_container = document.getElementById('required_field_errors');
    if (error_container) {
      error_container.innerText = 'You must answer all required fields';
    }
  }

  getNextSibling(elem, classSelector) {
    // Get the next sibling element
    let sibling = elem.nextElementSibling;

    // If there's no classSelector, return the first sibling
    if (!classSelector) return sibling;

    // If the sibling matches our classSelector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
      if (sibling.classList.contains(classSelector)) return sibling;
      sibling = sibling.nextElementSibling
    }
  }
}
