import ApplicationController from './application_controller'

const offcanvasOpenClass = 'offcanvas-collapse--open'

export default class extends ApplicationController {
  static targets = [
    'offcanvasTrigger',
    'offcanvas',
    'offcanvasOverlay'
  ]

  connect () {
    super.connect()
  }

  toggle() {
    if (this.offcanvasTarget.classList.contains(offcanvasOpenClass)) {
      this.close()
    } else {
      this.open()
    }
  }

  open() {
    this.offcanvasTarget.classList.add(offcanvasOpenClass)
    this.offcanvasOverlayTarget.classList.remove('d-none')
    this.disableBodyScroll()
  }

  close() {
    this.offcanvasTarget.classList.remove(offcanvasOpenClass)
    this.offcanvasOverlayTarget.classList.add('d-none')
    this.enableBodyScroll()
  }
}
