import ApplicationController from './application_controller'
import CableReady from 'cable_ready'
import consumer from "channels/consumer"

export default class extends ApplicationController {
  static values = {
    qaSessionId: Number,
    participantId: Number
  }

  connect() {
    super.connect()

    const that = this
    consumer.subscriptions.create(
      {
        channel: 'QASessionChannel',
        qa_session_id: this.qaSessionIdValue
      },
      {
        received (data) {
          if (data.cableReady) CableReady.perform(data.operations)
          if (data.head == 302 && data.path && data.participant_id == that.participantIdValue) {
            window.location.pathname = data.path
          }
        }
      }
    )
  }

  afterCreateQuestion(form){
    form.reset()
  }
}
