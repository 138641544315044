import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [
    "form", "table", "chart", "category", "subject", "secondSubject", "occurredRange", "createdRange"
  ]

  static values = {
    currentPage: Number
  }

  connect() {
  }

  initialize() {
    const urlParams = new URLSearchParams(window.location.search);
    this.currentPageValue = urlParams.get('page') ? urlParams.get('page') : 1;
  }

  submit() {
    // Stop the form from submitting
    event.preventDefault()

    // Get the form data from the submitted filter form
    let formData = new FormData(event.target)

    // Remove the commit button text from the params
    formData.delete('commit')
    formData.delete('authenticity_token')
    formData.append('page', this.currentPageValue)

    // parameterize the data with JS since it doesn't need to hit the server
    const params = new URLSearchParams(formData).toString()

    this.changeQueryString(params)

    // Update each table
    this.tableTargets.forEach(table => this.updateTable(table, params))

    // Update each chart
    this.chartTargets.forEach(chart => this.updateChart(chart, params))
    return false
  }

  updateTable(table, params) {
    const requestUrl = this.changeQueryString(params, table.getAttribute('data-url'), false)
    fetch(requestUrl)
      .then(response => response.text())
      .then(function (html) {
        table.outerHTML = html
      })
  }

  updateChart(chart, params) {
    let requestUrl = this.changeQueryString(params, chart.getAttribute('data-url'), false)
    Chartkick.charts[`${chart.getAttribute('data-id')}`].updateData(requestUrl)
  }

  paginate() {
    event.preventDefault()
    event.data('page')
    // Rails.fire(this.formTarget, 'submit')
  }
}
