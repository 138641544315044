import ApplicationController from './application_controller'

export default class extends ApplicationController {

    static values = {
      targetId: String,
    }

  connect() {
    const input = document.querySelector(`#${this.targetIdValue}`);
    window.intlTelInput(input, {
      hiddenInput: "intl_phone",
      separateDialCode: true,
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.3/js/utils.js"
    });
  }
}
