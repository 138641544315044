import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = [
    "orgList", "orgIdField", "orgNameField", "selectOrgField"
  ]

  connect () {
    super.connect()
  }

  selectNewOrg(e){
    this.orgNameFieldTarget.value = this.selectOrgFieldTarget.value = e.currentTarget.attributes['org-name'].value
    this.orgListTarget.innerHTML = this.orgIdFieldTarget.value = ""
  }

  selectExistingOrg(e){
    this.orgIdFieldTarget.value = e.currentTarget.attributes['org-id'].value
    this.selectOrgFieldTarget.value = e.currentTarget.attributes['org-name'].value
    this.orgListTarget.innerHTML = this.orgNameFieldTarget.value = ""
  }

  setOrganizationName(e) {
    const name = e.currentTarget.attributes['org-name'].value
    this.orgListTarget.innerHTML = ''
    this.stimulate('SelectOrganizationReflex#new_organization_member', name).then(() => {
      this.changeFormSuccess()
    })
  }

  setOrganizationId(e) {
    const id = e.currentTarget.attributes['org-id'].value
    this.selectOrgFieldTarget.value = e.currentTarget.attributes['org-name'].value
    this.orgListTarget.innerHTML = ''
    this.stimulate('SelectOrganizationReflex#existing_organization_member', id).then(() => {
      this.changeFormSuccess()
    })
  }

  changeFormSuccess() {
    ['#organization_member_started_on'].forEach(field => {
      flatpickr(field,
      { altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d"
      });
    });
  }
}
