import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = [
    'involedPartyTemplate'
  ]

  static values = {
    id: String,
    usersCount: Number,
    isEditingParty: Boolean,
    userPosition: String
  }

  connect() {
    super.connect()

    if (this.usersCountValue < 2)
      $('.remove-icon').hide();
  }

  newInvolvedParty(e){
    this.isEditingParty = false;
    let content = this.involedPartyTemplateTarget.innerHTML.replace(/NEW_RECORD/g, this.usersCountValue)
    e.currentTarget.insertAdjacentHTML('beforebegin', content)
    this.idValue = $(e.currentTarget).prev().children().attr('id')
    this.setFieldHtml('button[name="modal_button"]', 'Add Involved Party')
    this.createEvent('modal_open')
    this.usersCountValue++;
  }

  editInvolvedParty(e){
    this.isEditingParty = true;
    this.idValue = $(e.currentTarget).prev().prev().attr('id')
    if($('#' + this.idValue).val() || $('#' + this.idValue).next().val()) {
      this.setFieldValue('#name-field', $(`#${this.idValue}`).val())
      this.setFieldValue("#position-field", $('#' + this.idValue).next().val())
      this.setFieldHtml('button[name="modal_button"]', 'Update')
    }
    this.createEvent('modal_open')
  }

  removeInvolvedParty(event) {
    let wrapper = event.target.closest('.nested-fields')
    if(wrapper.dataset.newRecord == 'true'){
      wrapper.remove()
    }
    else{
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
    }
  }

  createInvolvedParty(e){
    let addParty = true;
    if($('#name-field').val() || $('#position-field').val()){
      if(($(`#${this.idValue}`).val() || $(`#${this.idValue}`).next().val()) || this.usersCountValue == 1)
        addParty = false;
      $(`#${this.idValue}`).val($('#name-field').val())
      $(`#${this.idValue}`).siblings().first().val($('#position-field').val())
      $('#' + this.idValue).hide()
      $('#' + this.idValue).next().hide()
      this.userPositionValue =  $('#position-field').val().length > 0 ? ` (${$('#position-field').val()})` : ''
      let new_party = `<span class='involved-party-name mb-3 form-control ml-3' data-action='click->incident#editInvolvedParty'>${$('#name-field').val()}${this.userPositionValue}</span>`
      if(addParty == true)
        $(`#${this.idValue}`).siblings().first().after(new_party);
      else
      $(`#${this.idValue}`).siblings()[1].innerHTML = $('#position-field').val().length > 0 ? $('#name-field').val() + this.userPositionValue :  $('#name-field').val()
      this.closeModal()
    }
    else {
      this.setFieldHtml('#involved-parties-error', 'Please enter either name or position.')
    }
    if($('.remove-icon')[0]) $('.remove-icon').show()
  }

  cancel(){
    if(this.isEditingParty === false) $('.nested-fields').last().remove()
    this.closeModal()
  }

  closeModal() {
    this.setFieldValue('#name-field', '')
    this.setFieldValue('#position-field', '')
    this.setFieldHtml('#involved-parties-error', '')
    this.createEvent('modal_close')
  };

  setFieldValue(element, value){
    $(element).val(value)
  }

  setFieldHtml(element, value){
    $(element).html(value)
  }

  createEvent(event_name){
    const event = new CustomEvent(event_name)
    window.dispatchEvent(event)
  }
}
