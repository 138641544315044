import ApplicationController from './application_controller'

export default class extends ApplicationController {

    static values = {
      targetId: String,
      targetType: String,
    }

  connect() {
    const options = {
      'single' : { altInput: true, altFormat: "F j, Y", dateFormat: "Y-m-d" },
      'range-with-callbacks' : { mode: "range", dateFormat: "Y-m-d", altInput: true, altFormat: "m/j/Y", maxDate: "today",
                                onClose: function(selectedDates, dateStr, instance) {
                                  if(selectedDates.length == 1){
                                    instance.setDate([selectedDates[0],selectedDates[0]], true);
                                  } else {
                                    instance.setDate([selectedDates[0],selectedDates[1]], true);
                                  }
                                }
                              },
      'range' : { mode: "range", enableTime: true, dateFormat: "Y-m-d H:i", altInput: true, altFormat: "F j, Y h:i K", maxDate: "today" },
      'incident' : { enableTime: true, dateFormat: "Y-m-d H:i", altInput: true, altFormat: "F j, Y h:i K", maxDate: "today" }
    }
    flatpickr(`#${this.targetIdValue}`, options[this.targetTypeValue]);
  }
}
