import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [
    'modalTrigger',
    'modal',
    'modalOverlay'
  ]

  connect () {
    super.connect()
  }

  open() {
    this.modalTarget.classList.add('d-block')
    this.modalOverlayTarget.classList.remove('d-none')
    this.disableBodyScroll()
  }

  close() {
    this.modalTarget.classList.remove('d-block')
    this.modalOverlayTarget.classList.add('d-none')
    this.enableBodyScroll()
  }

  stop() {
    event.stopImmediatePropagation() 
  }
}
