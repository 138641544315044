import ApplicationController from './application_controller'

export default class extends ApplicationController {

  selectAll(e){
    $('input[name="invite_ids[]"]').prop("checked", e.currentTarget.checked);
  }

  toggleCheckbox(e){
    if ($('input[name="invite_ids[]"]').length == $('input[name="invite_ids[]"]:checked').length){
      $('#select_all').prop("checked", true);
      return
    }
    $('#select_all').prop("checked", false);
  }
}
