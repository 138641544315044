import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = [
    'employmentStatus',
    'training',
    'mentalHealth',
    'reporting',
    'loveContract',
    'general'
  ]

  static values = {
    adType: String
  }

  connect() {
    if(this.adTypeValue == 'training') {
      this.trainingTarget.classList.remove('d-none')
    } else if(this.adTypeValue == 'reporting') {
      this.reportingTarget.classList.remove('d-none')
    } else if(this.adTypeValue == 'loveContract') {
      this.loveContractTarget.classList.remove('d-none')
    } else if(this.adTypeValue == 'mentalHealth') {
      this.mentalHealthTarget.classList.remove('d-none')
    } else if(this.adTypeValue == 'general') {
      this.generalTarget.classList.remove('d-none')
    }
  }
}
