import ApplicationController from './application_controller'


export default class extends ApplicationController {

  connect () {
    super.connect()
    this.user2faFieldsSuccess()
  }

  changeFormSuccess() {
    ['#user_demographic_date_of_birth',
    '#organization_member_started_on'].forEach(field => {
      flatpickr(field,
      { altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d"
      });
    });
  }

  user2faFieldsSuccess() {
    ['', '_confirmation'].forEach(field => {
      const element = document.querySelector("#employee_phone"+field);
      
      if (!element || element.dataset.intlTelInputId) return
      window.intlTelInput(document.querySelector("#employee_phone"+field), {
        separateDialCode: true,
        hiddenInput: "intl_phone"+field,
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.3/js/utils.js"
    }) });
  }

  user2faFormSuccess() {
    this.user2faFieldsSuccess()
    this.openModal()
  }

  user2faFormSubmitSuccess(form) {
    form.reset()
    this.openModal()
  }

  verify2faTokenSuccess() {
    window.location.replace("/dashboards/user/profile/edit?submission_type=two_factor_authentication");
  }

  verifyAndDisable2faSuccess() {
    this.verify2faTokenSuccess()
  }

  openModal() {
    this.createEvent("modal_open")
  }

  closeModal() {
    this.createEvent("modal_close")
    this.verify2faTokenSuccess()
  }

  createEvent(event_name) {
    const event = new CustomEvent(event_name)
    window.dispatchEvent(event)
  }
}
