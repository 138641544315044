import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["content"]

  connect() {
    this.hideContent()
  }

  toggleContent() {
    if (this.contentTarget.classList.contains("d-none")) {
      this.showContent()
    } else {
      this.hideContent()
    }
  }

  showContent() {
    this.contentTarget.classList.remove("d-none")
  }

  hideContent() {
    this.contentTarget.classList.add("d-none")
  }

  get contentTarget() {
    return this.targets.find("content")
  }
}
