import ApplicationController from './application_controller'
import consumer from "channels/consumer"

export default class extends ApplicationController {
  static values = {
    communicationRequestId: Number
  }

  connect() {
    super.connect()

    consumer.subscriptions.create(
      { channel: 'CommunicationRequestChannel', communication_request_id: this.communicationRequestIdValue },
      { received: this._cableReceived.bind(this) }
    )
  }

  createMessage(event) {
    event.preventDefault()
    this.stimulate('CommunicationRequest#create_message', event.target)
  }

  reportMessage(event){
    const message_id = $(event.target).attr('message-id')
    this.stimulate('CommunicationRequest#report_message', message_id)
  }

  shareIdentity(){
    this.stimulate('CommunicationRequest#toggle_share_identity')
  }

  afterCreateMessage(form){
    form.reset()
  }

  _cableReceived(){
    this.stimulate('CommunicationRequest#update_messages')
  }
}
