// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Allows `Rails` to be referenced elswhere rather than just requiring
import Rails from '@rails/ujs';
Rails.start()

require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
// require('jquery')
require("chartkick")
require("chart.js")

import "bootstrap";
import "bootstrap/scss/bootstrap";
import "intl-tel-input/build/css/intlTelInput.css";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/dark.css";

// this import needs to come after third party js libraries for css overrides to work
import "../../assets/stylesheets/application";
import "../public/survey_response_questions"

import intlTelInput from 'intl-tel-input';
global.intlTelInput = intlTelInput;

import flatpickr from "flatpickr";

import jstz from 'jstz';
global.jstz = jstz;

// import * as Video from 'twilio-video';
// global.Video = Video;


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers";
import '../public/event'
import "../public/event_attendee"
