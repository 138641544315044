import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['modal', 'loader', 'button']

  static values = {
    pendingReset: Boolean
  }

  connect () {
    super.connect()
  }

  initialize() {
    this.pendingResetValue = false
  }

  beforeSearch() {
    this.disableButtons()
  }
  afterSearch() {
    this.hideModal()
    this.enableButtons()
  }

  beforeReset() {
    this.disableButtons()
  }
  afterReset() {
    this.pendingResetValue = true
    this.resetForm()
    this.enableButtons()
  }

  hideModal() {
    event.preventDefault()
    this.enableBodyScroll()
    this.modalTarget.classList.add('d-none')
  }
  showModal() {
    this.modalTarget.classList.remove('d-none')
    this.disableBodyScroll()
    if (this.pendingResetValue) {
      this.resetForm()
    }
  }

  showLoader() {
    // Implemented in another branch, needs loaded
  }

  cancelChanges() {
    this.resetForm()
    this.hideModal()
  }

  resetForm() {
    this.modalTarget.reset();
    this.pendingResetValue = false
  }

  disableButtons() {
    this.buttonTargets.forEach(button => {button.classList.add('btn-loading'); button.disabled = true;} )
  }
  enableButtons() {
    this.buttonTargets.forEach(button => {button.classList.remove('btn-loading'); button.disabled = false;} )
  }

}
