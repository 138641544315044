import ApplicationController from './application_controller'
import $ from "jquery";
import Select2 from "select2";

require("select2/dist/css/select2");

export default class extends ApplicationController {
  connect() {
    $(".multiple-select-dropdown").select2();
  }
}
